import { Injectable, Injector, inject } from '@angular/core';
import { Challenge } from '../../shared/models/challenge';
import { FirestoreService } from './firestore.service';
import { EXERCISE_PATH } from './exercise.service';
import { convertSnaps } from '../utils/db-utils';
import { Exercise } from '../../shared/models/exercise';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Timestamp,
  collection,
  collectionSnapshots,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  runTransaction,
  where,
  writeBatch,
} from '@angular/fire/firestore';
import { firstValueFrom, map } from 'rxjs';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { getDaysDifference, getToday } from '../utils/date-utils';

export const CHALLENGE_PATH: string = 'challenges';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService extends FirestoreService<Challenge> {
  private functions: Functions = inject(Functions);

  constructor(injector: Injector) {
    super(injector, CHALLENGE_PATH);
  }

  getUpcomingChallengesWithSameParent(
    queryCol: CollectionReference<DocumentData>,
    parentChallengeRef: DocumentReference<DocumentData>,
    minDate: Date
  ) {
    const upcomingFormContainers$ = collectionSnapshots(
      query(queryCol, where('parentChallengeRef', '==', parentChallengeRef))
    ).pipe(
      map((snaps) => convertSnaps<Challenge>(snaps)),
      map((challenges) =>
        challenges.filter((challenge) => {
          return (
            challenge.scheduledAt && challenge.scheduledAt.toDate() > minDate
          );
        })
      )
    );
    return firstValueFrom(upcomingFormContainers$);
  }

  assignChallengesToCustomer(
    customerRef: DocumentReference<DocumentData>,
    newChallenges: Challenge[],
    newExercises: Exercise[],
    deletedChallenges: Challenge[]
  ) {
    return runTransaction(this.firestore, async (t) => {
      // New FormContainers
      newChallenges.forEach((newChallenge) => {
        // Create FormContainer
        const newChallengeRef = doc(
          collection(this.firestore, customerRef.path, CHALLENGE_PATH)
        );
        const updateChallenge: Partial<Challenge> = Object.assign(
          {},
          newChallenge
        );
        updateChallenge.createdAt = new Date() as any;
        delete updateChallenge.finishedAt;
        delete updateChallenge.startedAt;
        delete updateChallenge.feedback;
        delete updateChallenge.ref;
        t.set(newChallengeRef, updateChallenge);
        // Create FormElements
        newExercises.forEach((newExercise) => {
          const newExerciseRef = doc(
            collection(this.firestore, newChallengeRef.path, EXERCISE_PATH)
          );
          const updateExercise: Partial<Exercise> = Object.assign(
            {},
            newExercise
          );
          delete updateExercise.executedSets;
          delete updateExercise.ref;
          t.set(newExerciseRef, updateExercise);
        });
      });

      // Delete FormContainer
      deletedChallenges.forEach((deletedChallenge) =>
        t.delete(deletedChallenge.ref)
      );
    });
  }

  submit(
    ref: DocumentReference<DocumentData | unknown> | string,
    challenge: Partial<Challenge>,
    exercisesForChallenge: Exercise[]
  ) {
    return runTransaction(this.firestore, async (t) => {
      let redirectPath;
      if (typeof ref === 'string') {
        //ref = redirectPath = this.db.doc(ref).ref;
        ref = redirectPath = doc(this.firestore, ref);
        t.set(ref, challenge);
      } else {
        t.update(ref, challenge);
      }

      // DELETE exercises
      const oldExercisesCollectionRef = collection(
        this.firestore,
        ref.path,
        EXERCISE_PATH
      );
      const oldExercises = await getDocs(oldExercisesCollectionRef);
      if (!oldExercises.empty) {
        oldExercises.forEach((exercise) => t.delete(exercise.ref));
      }

      // SET exercises
      exercisesForChallenge.forEach((exercise: Partial<Exercise>, i) => {
        // Set index
        exercise.index = i;
        delete exercise.ref;
        // Create ref for exercise
        const exerciseRef = doc(oldExercisesCollectionRef);
        t.set(exerciseRef, exercise);
      });

      return redirectPath;
    });
  }

  async duplicateChallenge(
    challengePath: string,
    targetCollectionPath?: string,
    date?: Date
  ) {
    const fnDuplicateChallenge = httpsCallable(
      this.functions,
      'duplicateChallenge'
    );
    const res: any = await fnDuplicateChallenge({
      challengePath,
      targetCollectionPath,
      date,
    });
    const data = res.data;
    return {
      duplicatedChallengePath: data.duplicatedChallengePath as string,
      message: data.message as string,
      status: data.status as string,
    };
  }

  getUpcomingChallengeDuplicates(challenge?: Challenge) {
    if (!challenge?.ref || !challenge.parentChallengeRef) {
      return Promise.resolve([]);
    }

    const challengeCollectionRef = collection(
      this.firestore,
      challenge.ref.parent.path
    );
    const challenges$ = collectionSnapshots(
      query(
        challengeCollectionRef,
        where('parentChallengeRef', '==', challenge.parentChallengeRef)
      )
    ).pipe(
      map((snaps) => convertSnaps<Challenge>(snaps)),
      map((challenges) =>
        challenges.filter((upcomingChallenge) => {
          return (
            challenge.scheduledAt &&
            upcomingChallenge.scheduledAt &&
            upcomingChallenge.scheduledAt?.toDate() >
              (challenge.scheduledAt as any)
          );
        })
      )
    );
    return firstValueFrom(challenges$);
  }

  async bulkUpdateChallenges(
    sourceChallengeRef: DocumentReference<DocumentData>,
    sourceChallenge: Challenge,
    sourceChallengeExercises: Exercise[],
    upcomingChallenges: Challenge[]
  ) {
    const p1 = this.bulkUpdateChallenge(
      sourceChallengeRef,
      sourceChallenge,
      sourceChallengeExercises
    );
    const pN = upcomingChallenges.map((upcomingChallenge) =>
      this.bulkUpdateChallenge(
        upcomingChallenge.ref,
        sourceChallenge,
        sourceChallengeExercises,
        upcomingChallenge.scheduledAt
      )
    );
    await Promise.all([p1, ...pN]);
    return true;
  }

  async bulkUpdateChallenge(
    ref: DocumentReference<DocumentData>,
    challenge: Challenge,
    exercisesForChallenge: Exercise[],
    scheduledAt?: Timestamp | null
  ) {
    return runTransaction(this.firestore, async (t) => {
      const challengeUpdate: Partial<Challenge> = Object.assign({}, challenge);
      if (scheduledAt) {
        challengeUpdate.scheduledAt = scheduledAt;
      }
      t.update(ref, challengeUpdate);

      // DELETE exercises
      const oldExercisesCollectionRef = collection(
        this.firestore,
        ref.path,
        EXERCISE_PATH
      );
      const oldExercises = await getDocs(oldExercisesCollectionRef);
      if (!oldExercises.empty) {
        oldExercises.forEach((exercise) => t.delete(exercise.ref));
      }

      // SET exercises
      exercisesForChallenge.forEach((exercise: Partial<Exercise>, i) => {
        // Set index
        exercise.index = i;
        delete exercise.ref;
        // Create ref for exercise
        const exerciseRef = doc(oldExercisesCollectionRef);
        t.set(exerciseRef, exercise);
      });
      return true;
    });
  }

  async bulkDeleteChallenges(
    ...challengeRefs: DocumentReference<DocumentData>[]
  ) {
    const batch = writeBatch(this.firestore);
    challengeRefs.map((challengeRef) => batch.delete(challengeRef));
    return batch.commit();
  }

  getAdditionalChallengeCount(customerRef: DocumentReference<DocumentData>) {
    const collectionRef = collection(
      this.firestore,
      customerRef.path,
      CHALLENGE_PATH
    );
    const queryRef = query(collectionRef, where('scheduledAt', '==', null));
    return collectionSnapshots(queryRef).pipe(
      map((challenges) => {
        const unfinishedChallenges = challenges.filter(
          (challenge) => !(challenge.data() as Challenge).finishedAt
        );
        return unfinishedChallenges.length;
      })
    );
  }

  getRemainingChallengeDays(customerRef: DocumentReference<DocumentData>) {
    const collectionRef = collection(
      this.firestore,
      customerRef.path,
      CHALLENGE_PATH
    );
    const queryRef = query(
      collectionRef,
      orderBy('scheduledAt', 'desc'),
      where('scheduledAt', '>=', getToday()),
      limit(1)
    );
    return collectionSnapshots(queryRef).pipe(
      map((challenges) => {
        if (challenges.length > 0) {
          const challenge = challenges[0].data() as Challenge;
          return getDaysDifference(new Date(), challenge.scheduledAt!.toDate());
        } else {
          return null;
        }
      })
    );
  }
}
